import PrimaryContentContainer from './PrimaryContentContainer'

const SomethingWentWrong = props => {
  const GoBackLink = () => {
    if (props.goBackLink)
      return (
        <a
          onClick={(window.onbeforeunload = null)}
          href={props.goBackLink}
          style={{ color: 'var(--main-color)' }}>
          {props.goBackText ?? 'Go back'}
        </a>
      )
    return <></>
  }

  const ExitLink = () => {
    if (!props.exitLink) return <></>
    return (
      <>
        {props.goBackLink && <> &middot; </>}
        <a
          onClick={(window.onbeforeunload = null)}
          href={props.exitLink}
          style={{ color: 'var(--main-color)' }}>
          Exit
        </a>
      </>
    )
  }

  return (
    <PrimaryContentContainer
      title={props.title ? props.title : 'Something went wrong.'}
      hideBottom={true}
      {...props}>
      {props.children}
      <GoBackLink />
      <ExitLink />
    </PrimaryContentContainer>
  )
}

export default SomethingWentWrong
