import '../../components/PrimaryContentContainer.css'
import './AccountsPage.css'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import Redirect from '../../helpers/redirectHelper'
import Loader from '../../components/Loader'
import RenderConnection from '../../components/RenderConnection'
import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

function AccountsPage() {
  const hasLoadedUser = useSelector(({ hasLoadedUser }) => hasLoadedUser)
  const user = useSelector(({ user }) => user)
  const connections = useSelector(({ connections }) => connections)
  const [prevRewards, setPrevRewards] = useState({})
  const [loadingPrevRewards, setLoadingPrevRewards] = useState(false)
  let accountNumber = connections
    .map(c => c['accounts'].length)
    .reduce((a, b) => a + b, 0)

  /*
  useEffect(() => {
    if (hasLoadedUser && user.username && Object.keys(prevRewards).length === 0) {
      setLoadingPrevRewards(true)
      API.get('userGateway', '/data/rewardhistory', {
        queryStringParameters: {
          user_id: user.username
        }
      }).then(res => {
        if (res && !res.error) {
          setPrevRewards(res)
          setLoadingPrevRewards(false)
        }
      })
      .catch(err => {setLoadingPrevRewards(false)})
    }
  }, [hasLoadedUser, prevRewards, user.username])
  */

  if (!hasLoadedUser || loadingPrevRewards) return <Loader color='white' />

  /*
  const leftToRedeemText = () => {
    let text = ''
    if (prevRewards.hasOwnProperty('history')) {
      const left = 10 - prevRewards['history'].length - prevRewards['scheduled'].length
      text = `You currently have ${left > 0 ? left : 'no'} reward${left === 1 ? '' : 's'} left to redeem. \n
      ${left === 0 ? 'Please wait until the accounts are about to expire to be eligible for points again.' : ''}`
    }
    return text
  }
  */
  return (
    <Redirect
      to='/portal/login'
      ifAuthStateIs={false}
      addParams={{ redirect: 'portal/accounts' }}>
      <Container fluid className='primary-content-container accounts-page'>
        <Container className='main-container bg-transparent'>
          <p
            className='pt-1 text-left'
            style={{
              fontSize: 20,
            }}>
            <p className='font-weight-bold pb-2'>
              We have made the decision to stop collecting financial data
              through the existing YouGov Finance platform.
            </p>
            {hasLoadedUser && connections.length > 0 && (
              <p style={{ fontSize: 20 }}>
                Thank you for your participation in YouGov Finance! <br />{' '}
                <br />
                You can still revoke your consent to YouGov Finance at any time
                using the button below, which will stop all sharing of your
                transaction data with us. <br /> <br />
              </p>
            )}
            Fortunately, we are also exploring a new and improved way for you to
            earn points by sharing your banking transaction history with us, and
            we hope to share more information about it soon.
            <br /> <br />
            If you have any questions, please do not hesitate to contact our
            support team by using the following{' '}
            <a href='https://yougov.zendesk.com/hc/en-gb/requests/new?tf_subject=Finance'>
              link
            </a>
            . <br /> <br />
          </p>
          {/*
          <p
            className='font-weight-bold text-center'
            style={{ display: accountNumber > 0 ? 'block' : 'none' }}>
            You've added {accountNumber} account{accountNumber === 1 ? '' : 's'}
            !
          </p>
          */}
          {connections.length > 0 && (
            <h3 className='pb-4'>Your Connections:</h3>
          )}
          {connections.map(c => (
            <RenderConnection key={c.token} conn={c} mode={'manage'} />
          ))}
          {/*}
          <p className='text-center pt-4' style={{fontSize: 16, display: prevRewards.hasOwnProperty('history') && accountNumber > 0 ? 'block' : 'none'}}>
            {leftToRedeemText()}
          </p>
          */}
          <div className='d-flex justify-content-center py-4'>
            {/*
            {accountNumber < 10 && (
              <a
                className='button d-flex align-items-center'
                href='/portal/add-bank-account'>
                Add {accountNumber > 0 ? 'another ' : ''}bank account
              </a>
            )}
            */}
            {accountNumber > 0 && (
              <a
                className='button d-flex align-items-center ml-2'
                href='/portal/data'>
                See your spending
              </a>
            )}
          </div>
        </Container>
      </Container>
    </Redirect>
  )
}

export default AccountsPage
