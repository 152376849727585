import AccountsPage from './AccountsPage'
import AuthPage from './AuthPage'
import DataHubPage from './DataHubPage'
import LandingPage from './LandingPage'
import Page404 from './Page404'
import SignInPage from './SignInPage'
import SignUpPage from './SignUpPage'
import SSOPage from './SSOPage'
import TruelayerRedirectPage from './TruelayerRedirectPage'
import ResetPasswordPage from './ResetPasswordPage'
import PolicyPage from './PolicyPage'

const PortalPages = {
  AccountsPage,
  AuthPage,
  LandingPage,
  Page404,
  SignInPage,
  SignUpPage,
  SSOPage,
  DataHubPage,
  TruelayerRedirectPage,
  ResetPasswordPage,
  PolicyPage,
}

export default PortalPages
