import { Spinner } from 'react-bootstrap'
import loginManager from '../helpers/loginManager'
import { Trash } from 'react-bootstrap-icons'
import store from '../store/store'
import crossMarkLogo from '../assets/icons8-cross-mark-96.png'
import checkMarkLogo from '../assets/icons8-tick-box-144.png'
import RenderAccount from './RenderAccount'
import { useState } from 'react'
const sandbox = process.env.REACT_APP_ENV !== 'prod'

const RenderConnection = ({
  conn: {
    accounts,
    expired,
    loading,
    nonce,
    reconsent_status,
    reauth_link,
    remaining,
    revoked,
    selected,
    token,
  },
  mode = 'display',
  dev_real = false,
}) => {
  const createdMoreThanTwoMinutesAgo = createdAt => {
    if (
      createdAt &&
      new Date(createdAt).getTime() > new Date().getTime() - 1000 * 60 * 2
    ) {
      alert(
        'Please wait at least two minutes after adding your account to delete it.'
      )
      return false
    }
    return true
  }

  const [revokeClicked, setRevokeClicked] = useState(false)

  const getExpiredText = () => {
    if (revoked) {
      return mode === 'manage'
        ? 'Access revoked. Reconsent' +
            (remaining < 14 ? ' to gain points:' : ':')
        : 'Access revoked'
    } else if (expired || remaining < 1) {
      return mode === 'manage'
        ? 'Access has expired. Reconsent' +
            (remaining < 14 ? ' to gain points:' : ':')
        : 'Access expired'
    } else {
      if (remaining < 14) {
        return mode === 'manage'
          ? 'Access expires in ' +
              remaining.toString() +
              ' day' +
              (remaining === 1 ? '' : 's') +
              '. Reconsent to gain points'
          : remaining.toString() + ' days of access left'
      }
      return remaining.toString() + ' days of access left'
    }
  }

  const truelayerURL =
    'https://auth.truelayer' +
    (sandbox ? '-sandbox' : '') +
    '.com/?response_type=code&client_id=' +
    (sandbox ? 'sandbox-' : '') +
    'leanapp-0879d9&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=' +
    window.location.origin +
    '/save-bank-account/&providers=uk-'

  window.onbeforeunload = null
  return (
    <div key={token} style={{ paddingBottom: 20 }}>
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <img
            style={{ height: 30, width: 'auto', marginRight: 10 }}
            alt={accounts[0].display_name}
            src={accounts[0].provider_logo_uri}
          />
          <span
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              marginLeft: 10,
            }}>
            {accounts[0]['provider_display_name']
              .split(' ')
              .map(a => a[0].toUpperCase() + a.slice(1))
              .join(' ')}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          {/*
          {mode !== 'display' && (
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>
              {getExpiredText()}
            </span>
          )}
          */}
          {/*
          {mode === 'manage' && (expired || revoked || remaining < 14) && (
            <button
              id={'refreshButton ' + token}
              style={{
                fontSize: 12,
                paddingLeft: 'calc(6px + 1vw)',
                paddingRight: 'calc(6px + 1vw)',
                marginLeft: 15,
              }}
              onClick={e => {
                window.location.href = '/reconfirm-consent'
              }}>
              Reconsent
              <Spinner
                animation='border'
                variant='dark'
                size='sm'
                style={{ display: 'inline-block' }}
              />
            </button>
          )}
          */}
          {mode === 'manage' && !revoked && (
            <button
              id={'revokeButton ' + token}
              style={{
                fontSize: 12,
                paddingLeft: 'calc(6px + 1vw)',
                paddingRight: 'calc(6px + 1vw)',
                marginLeft: 15,
              }}
              onClick={e => {
                if (
                  window.confirm(
                    `Are you sure you want to revoke consent for ${accounts[0][
                      'provider_display_name'
                    ]
                      .split(' ')
                      .map(a => a[0].toUpperCase() + a.slice(1))
                      .join(' ')}?`
                  )
                ) {
                  e.target.classList.add('loading')
                  setRevokeClicked(true)
                  loginManager.revokeConnections(
                    [token],
                    accounts[0].user_id,
                    window.location.origin + '/save-bank-account/',
                    false
                  )
                }
              }}>
              {!revokeClicked && 'Revoke Consent'}
              <Spinner
                animation='border'
                variant='dark'
                size='sm'
                style={{ display: 'inline-block' }}
              />
            </button>
          )}
          {mode === 'manage' && revoked && (
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>
              Access revoked
            </span>
          )}
          {mode === 'select' && (
            <input
              type='checkbox'
              checked={selected}
              style={{ marginLeft: 15 }}
              onChange={() => {
                let conns = [...store.getState().connections]
                conns[conns.findIndex(c => c.token === token)].selected =
                  selected !== undefined ? !selected : true
                return store.dispatch({
                  type: 'changeConnections',
                  payload: conns,
                })
              }}
            />
          )}
          {mode === 'display' && loading && (
            <Spinner
              animation='border'
              variant='dark'
              size='sm'
              style={{ display: 'inline-block' }}
            />
          )}
          {mode === 'display' &&
            !loading &&
            reconsent_status &&
            (reconsent_status === 'failed' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <img
                  style={{ height: 25 }}
                  className='rounded'
                  alt={'crossMark'}
                  src={crossMarkLogo}
                />
                <span style={{ marginLeft: 10 }}>
                  Something went wrong. Please try again later.
                </span>
              </div>
            ) : reconsent_status === 'no_action_needed' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingRight: 30,
                }}>
                <img
                  style={{ height: 25 }}
                  className='rounded'
                  alt={'checkMark'}
                  src={checkMarkLogo}
                />
                <span style={{ marginLeft: 10 }}>Reconsented</span>
              </div>
            ) : (
              <div>
                <span>
                  {reconsent_status === 'action_needed'
                    ? ''
                    : reconsent_status === 'cannot_be_extended'
                    ? 'This connection cannot be extended yet. Please re-add it the normal way.'
                    : 'Something went wrong. Please try re-authenticating.'}
                </span>
                <button
                  id={'refreshButton ' + token}
                  style={{
                    fontSize: 12,
                    paddingLeft: 'calc(6px + 1vw)',
                    paddingRight: 'calc(6px + 1vw)',
                    marginLeft: 15,
                  }}
                  onClick={e => {
                    let conns = [...store.getState().connections]
                    conns[
                      conns.findIndex(c => c.token === token)
                    ].loading = true
                    store.dispatch({
                      type: 'changeConnections',
                      payload: conns,
                    })
                    conns = conns.filter(c => c.selected)
                    loginManager
                      .storeReauthenticationState(
                        token,
                        nonce,
                        accounts[0].user_id,
                        conns.map(c => c.token),
                        conns.map(c => c.reconsent_status),
                        conns.map(c => c.reauth_link),
                        conns.map(c => c.nonce),
                        dev_real
                      )
                      .then(res => {
                        window.location.href = reauth_link
                          ? reauth_link
                          : truelayerURL +
                            (sandbox && accounts[0].provider_id === 'mock'
                              ? 'cs-mock%20uk-ob-all%20uk-oauth-all'
                              : 'uk-ob-all%20uk-oauth-all')
                      })
                  }}>
                  {reconsent_status === 'action_needed'
                    ? 'Further action needed'
                    : 'Re-authenticate'}
                </button>
              </div>
            ))}
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridGap: 20,
          width: '95%',
          marginLeft: '2.5%',
        }}>
        {accounts.map((ac, j) => (
          <div
            key={'account' + ac.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 5,
            }}>
            {RenderAccount(ac)}
            {mode === 'manage' && (
              <button
                id={'deleteButton' + j}
                style={{
                  height: 30,
                  fontSize: 16,
                  paddingLeft: 'calc(1px + 1vw)',
                  paddingRight: 'calc(1px + 1vw)',
                  opacity: 0.5,
                }}
                onClick={e => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete account '${ac.display_name}'? You will not receive points for adding this account at a later date.`
                    ) &&
                    createdMoreThanTwoMinutesAgo(ac.createdAt)
                  ) {
                    e.target.classList.add('loading')
                    loginManager.deleteAccount(ac)
                  }
                }}>
                <Trash style={{ marginTop: '-1px' }} />
                <Spinner
                  animation='border'
                  variant='dark'
                  size='sm'
                  style={{ display: 'inline-block' }}
                />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default RenderConnection
