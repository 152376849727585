import { Redirect as OtherRedirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AuthState } from '@aws-amplify/ui-components'
import URLParamHelper from '../helpers/URLParamHelper'

function Redirect(props) {
  const hasLoadedUser = useSelector(({ hasLoadedUser }) => hasLoadedUser)
  const authState = useSelector(({ authState }) => authState)

  if (!hasLoadedUser) return <>{props.children}</>

  // if hasLoadedUser and authState does not match the redirect auth state, don't redirect
  const ifAuthStateIs = props.ifAuthStateIs
  let isAuthed = authState === AuthState.SignedIn
  if (ifAuthStateIs !== null && isAuthed !== ifAuthStateIs)
    return <>{props.children}</>

  const to = props.to || null
  const addParams = props.addParams || {}
  const passParams = props.passParams || false

  let URL = ''
  if (to !== null) URL = to
  else if (URLParamHelper.get('redirect'))
    URL = '/' + URLParamHelper.get('redirect')
  if (URL === '') URL = '/portal'

  const oldParams = URLParamHelper.getParams()
  delete oldParams.redirect
  if (URL !== '' && oldParams.from === URL.substring(1)) delete oldParams.from

  const paramsToPass = { ...(passParams ? oldParams : {}), ...addParams }
  let fullUrl = URL + '/?' + URLParamHelper.paramsToURL(paramsToPass)

  window.onbeforeunload = null
  return <OtherRedirect to={fullUrl} />
}

export default Redirect
