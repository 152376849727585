import React from 'react'

import { AuthState } from '@aws-amplify/ui-components'

import { Navbar, Container, NavDropdown, Spinner } from 'react-bootstrap'
import { PersonCircle } from 'react-bootstrap-icons'
import loginManager from '../helpers/loginManager'
import { useSelector } from 'react-redux'
import Logo from './Logo'

function PortalNavBar(props) {
  const user = useSelector(({ user }) => user)
  const authState = useSelector(({ authState }) => authState)
  const hasLoadedUser = useSelector(({ hasLoadedUser }) => hasLoadedUser)
  const connections = useSelector(({ connections }) => connections)

  const signInButtonText = () => {
    if (hasLoadedUser && authState === AuthState.SignedIn)
      return user?.attributes?.email ?? 'Signed in'
    if (hasLoadedUser) return 'Sign in'
    return <Spinner key='spinner' animation='border' size='sm' />
  }

  return (
    <Navbar id='navbar' expand='xl' variant='dark'>
      <Container
        id='navbar-container'
        className='justify-content-center justify-content-sm-between'>
        <Navbar.Brand
          href={
            hasLoadedUser && authState === AuthState.SignedIn
              ? '/portal/accounts/'
              : '/portal'
          }
          className='App-logo'>
          <Logo height='20px' color='white' />
        </Navbar.Brand>
        {(props.hideRightItems === undefined || !props.hideRightItems) && (
          <div style={{ display: 'flex' }}>
            <a
              href='http://business.yougov.finance/'
              className='d-none d-sm-inline-block'
              style={{
                color: 'white',
                marginRight: 15,
                fontSize: 12,
                lineHeight: '15px',
                paddingTop: 7,
              }}>
              For Business
            </a>
            <a
              href={hasLoadedUser ? '/portal/login' : null}
              className='justify-content-end'
              style={{
                display: authState === AuthState.SignedIn ? 'none' : 'block',
                backgroundColor: 'var(--nav-dropdown-back, white)',
                padding: '5px',
                paddingLeft: '16px',
                paddingRight: '16px',
                color: 'var(--main-color, black)',
                textDecoration: 'none',
                borderRadius: 50,
                fontSize: 13,
                fontWeight: 600,
              }}>
              {signInButtonText()}
              <PersonCircle style={{ marginLeft: 5 }} />
            </a>
            <NavDropdown
              key='navdropdown0'
              title={[
                <PersonCircle key='icon' style={{ marginRight: 5 }} />,
                signInButtonText(),
              ]}
              id='basic-nav-dropdown'
              style={{
                display:
                  hasLoadedUser && authState === AuthState.SignedIn
                    ? 'block'
                    : 'none',
              }}>
              {hasLoadedUser && connections.length > 0 && (
                <NavDropdown.Item href='/portal/accounts'>
                  Manage accounts
                </NavDropdown.Item>
              )}
              <NavDropdown.Item onClick={loginManager.signOut}>
                Sign out
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href='/terms'>
                Terms & Conditions
              </NavDropdown.Item>
              <NavDropdown.Item href='/privacy-policy'>
                Privacy Policy
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        )}
      </Container>
    </Navbar>
  )
}

export default PortalNavBar
