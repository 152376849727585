/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      yougov_id
      accounts {
        items {
          id
          user_id
          type
          account_type
          card_network
          card_type
          currency
          display_name
          iban
          name_on_card
          number
          partial_card_number
          provider_display_name
          provider_id
          provider_logo_uri
          routing_number
          sort_code
          swift_bic
          valid_from
          valid_to
          refresh_token
          consent_expired
          consent_last_renewed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      yougov_id
      came_from
      accounts {
        items {
          id
          user_id
          type
          account_type
          card_network
          card_type
          currency
          display_name
          iban
          name_on_card
          number
          partial_card_number
          provider_display_name
          provider_id
          provider_logo_uri
          routing_number
          sort_code
          swift_bic
          valid_from
          valid_to
          refresh_token
          consent_expired
          consent_last_renewed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      yougov_id
      accounts {
        items {
          id
          user_id
          type
          account_type
          card_network
          card_type
          currency
          display_name
          iban
          name_on_card
          number
          partial_card_number
          provider_display_name
          provider_id
          provider_logo_uri
          routing_number
          sort_code
          swift_bic
          valid_from
          valid_to
          refresh_token
          consent_expired
          consent_last_renewed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      user_id
      type
      account_type
      card_network
      card_type
      currency
      display_name
      iban
      name_on_card
      number
      partial_card_number
      provider_display_name
      provider_id
      provider_logo_uri
      routing_number
      sort_code
      swift_bic
      valid_from
      valid_to
      refresh_token
      consent_expired
      consent_last_renewed
      createdAt
      updatedAt
    }
  }
`
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      user_id
      type
      account_type
      card_network
      card_type
      currency
      display_name
      iban
      name_on_card
      number
      partial_card_number
      provider_display_name
      provider_id
      provider_logo_uri
      routing_number
      sort_code
      swift_bic
      valid_from
      valid_to
      refresh_token
      consent_expired
      consent_last_renewed
      createdAt
      updatedAt
    }
  }
`
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      user_id
      type
      account_type
      card_network
      card_type
      currency
      display_name
      iban
      name_on_card
      number
      partial_card_number
      provider_display_name
      provider_id
      provider_logo_uri
      routing_number
      sort_code
      swift_bic
      valid_from
      valid_to
      refresh_token
      consent_expired
      consent_last_renewed
      createdAt
      updatedAt
    }
  }
`
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      account_id
      user_id
      timestamp
      description
      amount
      currency
      transaction_type
      transaction_category
      transaction_classification
      merchant_name
      createdAt
      updatedAt
    }
  }
`
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      account_id
      user_id
      timestamp
      description
      amount
      currency
      transaction_type
      transaction_category
      transaction_classification
      merchant_name
      createdAt
      updatedAt
    }
  }
`
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      account_id
      user_id
      timestamp
      description
      amount
      currency
      transaction_type
      transaction_category
      transaction_classification
      merchant_name
      createdAt
      updatedAt
    }
  }
`
