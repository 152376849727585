import './AuthUI.css'

import React from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'

import PrimaryContentContainer from '../components/PrimaryContentContainer'

function AuthUI(props) {
  const loading = props.loading

  function onSubmit(e) {
    props.onSubmit()
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <PrimaryContentContainer hideBottom={true} noMinHeight={true} {...props}>
      <Form className='authForm' validated={false} onSubmit={onSubmit}>
        <Container fluid>
          <Row>
            <Col md={12} className='error'>
              {props.errorMessage}
            </Col>
          </Row>
          <Row>
            <Col md={6} style={{ display: props.noEmail ? 'none' : 'block' }}>
              <Form.Group controlId='authForm.email'>
                <Form.Label>Email address *</Form.Label>
                <Form.Control
                  required={!props.noEmail}
                  name='email'
                  onChange={props.onChange}
                  type='email'
                  placeholder='name@example.com'
                />
              </Form.Group>
            </Col>
            <Col
              md={6}
              style={{ display: props.noPassword ? 'none' : 'block' }}>
              <Form.Group controlId='authForm.password'>
                <Form.Label>Password *</Form.Label>
                <Form.Control
                  minLength={6}
                  required={!props.noPassword}
                  name='password'
                  onChange={props.onChange}
                  type='password'
                  placeholder='Password'
                />
              </Form.Group>
            </Col>
            <Col
              md={6}
              style={{ display: props.noValidation ? 'none' : 'block' }}>
              <Form.Group controlId='authForm.verificationCode'>
                <Form.Label>Verification code *</Form.Label>
                <Form.Control
                  required={!props.noValidation}
                  name='verificationCode'
                  onChange={props.onChange}
                  type='number'
                  placeholder='Verification code'
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className='link-text'>
              {props.forgotPasswordText}
            </Col>
          </Row>
          <Row>
            <Col sm={12} className='button-col link-text'>
              {props.otherLink}
              <Button variant='primary' type='submit' disabled={loading}>
                <Spinner
                  animation='border'
                  variant='dark'
                  size='sm'
                  style={{ display: loading ? 'inline-block' : 'none' }}
                />
                {loading ? '' : props.submitText ? props.submitText : 'Submit'}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </PrimaryContentContainer>
  )

  //alert(loginManager.formState)

  /* In the UI of the app, render forms based on form state */
  /* If the form state is "signUp", show the sign up form */
  // if (loginManager.formState === "signUp") {
  //   return (
  //     <div>
  //       <input
  //         name="username"
  //         onChange={loginManager.onChange}
  //       />
  //       <input
  //         name="password"
  //         type="password"
  //         onChange={loginManager.onChange}
  //       />
  //       <input
  //         name="email"
  //         onChange={loginManager.onChange}
  //       />
  //       <button onClick={loginManager.signUp}>Sign Up</button>
  //     </div>
  //   )
  // }
  //
  // /* If the form state is "confirmSignUp", show the confirm sign up form */
  // if (loginManager.formState === "confirmSignUp") {
  //   return (
  //     <div>
  //       <input
  //         name="username"
  //         onChange={loginManager.onChange}
  //       />
  //       <input
  //         name="verificationCode"
  //         onChange={loginManager.onChange}
  //       />
  //       <button onClick={loginManager.confirmSignUp}>Confirm Sign Up</button>
  //     </div>
  //   )
  // }
  //
  // /* If the form state is "signIn", show the sign in form */
  // if (loginManager.formState === "signIn") {
  //   return (
  //     <div>
  //       <input
  //         name="username"
  //         onChange={loginManager.onChange}
  //       />
  //       <input
  //         name="password"
  //         onChange={loginManager.onChange}
  //       />
  //       <button onClick={loginManager.signIn}>Sign In</button>
  //     </div>
  //   )
  // }
  //
  // /* If the form state is "signedIn", show the app */
  // if (loginManager.formState === "signedIn") {
  //   return (
  //     <div>
  //       <h1>Welcome to my app!</h1>
  //     </div>
  //   )
  // }
}

export default AuthUI
