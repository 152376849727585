const RenderAccount = ac => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 5,
      }}>
      <span
        style={{
          height: 30,
          maxHeight: 30,
          lineHeight: '25px',
          paddingTop: 5,
          fontSize: 16,
        }}
        className='text-sm-left text-truncate text-center'>
        {ac.display_name}
      </span>
      <span
        style={{
          height: 30,
          lineHeight: '25px',
          paddingBottom: 2,
          fontSize: 16,
          color: 'rgba(255,255,255,.6)',
        }}
        className='text-sm-left text-truncate text-center'>
        {ac.type === 'card'
          ? 'XXXX-XXXX-XXXX-' + ac.partial_card_number
          : 'XX-XX-' +
            ac.sort_code.substring(
              ac.sort_code.length - 2,
              ac.sort_code.length
            ) +
            ' | XXXX-' +
            ac.number.substring(ac.number.length - 4, ac.number.length)}
      </span>
    </div>
  )
}

export default RenderAccount
