const Logo = props => (
  <svg
    height={props.height}
    viewBox='0 0 336 41'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    style={{ display: 'inline-block' }}>
    <g
      id='Custom-Preset'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      fontFamily='Rawline'
      fontWeight='bold'>
      <g
        id='Group'
        transform='translate(-0.500000, -19.000000)'
        fill={props.color ? props.color : 'var(--main-color)'}>
        <text id='YouGov-Finance' fontSize='50' letterSpacing='-3'>
          <tspan x='0.816894531' y='56'>
            YouGov{' '}
          </tspan>
          <tspan x='172.810059' y='56' fontFamily='Rawline' fontWeight='normal'>
            Finance
          </tspan>
        </text>
        <text id='®' fontSize='10' letterSpacing='-0.2'>
          <tspan x='165.395898' y='29'>
            ®
          </tspan>
        </text>
      </g>
    </g>
  </svg>
)

export default Logo
