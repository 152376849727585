import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Amplify } from 'aws-amplify'
import prodconfig from './config/prod'
import devconfig from './config/dev'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Provider } from 'react-redux'
import store from './store/store'

const ENV = process.env.REACT_APP_ENV
const PROD = ENV === 'prod'
const config = PROD ? prodconfig : devconfig

// All these values were copied from the aws exports files generated in the prod and dev environments.
Amplify.configure({
  ...config,
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: config.aws_cognito_identity_pool_id,
    // REQUIRED - Amazon Cognito Region
    region: config.aws_cognito_region,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.aws_user_pools_id,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
  },
  API: {
    endpoints: [
      {
        name: 'userGateway',
        endpoint: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
        custom_header: async () => ({
          key: 'x-api-key',
          value: '2jK6QbsI7BDx4TdM3GB63sNyvhBbwMB2onYHFRs8',
        }),
      },
    ],
  },
})

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter forceRefresh={true}>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
