import React from 'react'
import AuthUI from '../../components/AuthUI'
import { useSelector } from 'react-redux'
import URLParamHelper from '../../helpers/URLParamHelper'
import { Container } from 'react-bootstrap'
import Redirect from '../../helpers/redirectHelper'
import Loader from '../../components/Loader'

function SignInPage(props) {
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  // const [currentEmail, setCurrentEmail] = React.useState("")
  const hasLoadedUser = useSelector(({ hasLoadedUser }) => hasLoadedUser)

  if (URLParamHelper.hasParams()) {
    window.onbeforeunload = () => true
  }

  // let defaultEmail = ''
  // if (URLParamHelper.get('email')) {
  //   defaultEmail = URLParamHelper.get('email')
  //   onChange({ target: { name: "email", value: defaultEmail } })
  // }

  function onChange(e) {
    props.loginManager.onChange(e)
    // if (props.loginManager.authFormState.email !== currentEmail) setCurrentEmail(props.loginManager.authFormState.email)
  }

  function signIn() {
    setErrorMessage('')
    setLoading(true)
    props.loginManager.signIn().catch(e => {
      setErrorMessage(e.message)
      setLoading(false)
    })
  }

  const getLink = page => {
    // let params = URLParamHelper.getParams()
    // if (currentEmail !== "") params.email = currentEmail
    return '/' + page //+ "/?" + URLParamHelper.paramsToURL(params)
  }

  if (!hasLoadedUser) return <Loader />

  const FromMessage = () => {
    const MessageContainer = ({ message }) => (
      <Container
        style={{
          padding: 40,
          textAlign: 'left',
          maxWidth: 'var(--primary-max-width)',
          color: 'var(--main-color)',
        }}>
        {message}
      </Container>
    )
    const from = URLParamHelper.get('from')
    if (from === 'reset-password')
      return (
        <MessageContainer message='Your password was reset successfully. Please sign in using your new password.' />
      )
    if (from === 'add-bank-account')
      return (
        <MessageContainer message='Please sign in before you add your bank account.' />
      )
    return <></>
  }

  return (
    <Redirect ifAuthStateIs={true} passParams={true}>
      <FromMessage />
      <AuthUI
        onChange={onChange}
        onSubmit={signIn}
        title='Sign in'
        submitText='Sign in'
        errorMessage={errorMessage}
        // email={defaultEmail}
        loading={loading}
        noValidation={true}
        noMinHeight={true}
        forgotPasswordText={
          <span>
            Forgot your password?{' '}
            <a href={getLink('portal/reset-password')}>Reset password</a>
          </span>
        }
        otherLink={
          <span>
            No account? <a href={getLink('portal/sign-up')}>Create account</a>
          </span>
        }></AuthUI>
    </Redirect>
  )
}

export default SignInPage
