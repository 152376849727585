import React from 'react'

import AuthUI from '../../components/AuthUI'
import { AuthState } from '@aws-amplify/ui-components'
import { useSelector } from 'react-redux'
import URLParamHelper from '../../helpers/URLParamHelper'

function ResetPasswordPage(props) {
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  // const [currentEmail, setCurrentEmail] = React.useState("")

  const authState = useSelector(({ authState }) => authState)

  if (URLParamHelper.hasParams()) {
    //window.onbeforeunload = () => true
  }

  function onChange(e) {
    props.loginManager.onChange(e)
    // if (props.loginManager.authFormState.email !== currentEmail) setCurrentEmail(props.loginManager.authFormState.email)
  }

  function forgotPassword() {
    setErrorMessage('')
    setLoading(true)
    props.loginManager
      .forgotPassword()
      .then(() => {
        setLoading(false)
      })
      .catch(e => {
        setErrorMessage(e.message)
        setLoading(false)
      })
  }

  function resetPassword() {
    setErrorMessage('')
    setLoading(true)
    props.loginManager
      .forgotPasswordSubmit()
      .then(() => {
        setLoading(false)
      })
      .catch(e => {
        setErrorMessage(e.message)
        setLoading(false)
      })
  }

  const getLink = page => {
    // let paramToPass = params
    // if (currentEmail !== "") paramToPass.email = currentEmail
    return '/' + page //+ "/?" + URLParamHelper.paramsToURL(params)
  }

  if (authState === AuthState.ResetPassword) {
    return (
      <AuthUI
        key={1}
        onChange={onChange}
        onSubmit={resetPassword}
        errorMessage={errorMessage}
        title='Reset your password'
        submitText='Reset password'
        // email={props.loginManager.authFormState.email}
        noEmail={true}
        loading={loading}
        otherLink={<span></span>}></AuthUI>
    )
  }

  return (
    <AuthUI
      key={0}
      onChange={onChange}
      onSubmit={forgotPassword}
      errorMessage={errorMessage}
      // email={URLParamHelper.get('email')}
      title='Forgot password'
      submitText='Send code'
      noPassword={true}
      noValidation={true}
      loading={loading}
      otherLink={
        <span>
          <a href={getLink('portal/login')}>Back to sign in</a>
        </span>
      }></AuthUI>
  )
}

export default ResetPasswordPage
