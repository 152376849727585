import '../AddBankAccountPage/AddBankAccountPage.css'
import { Auth, API } from 'aws-amplify'
import { useState } from 'react'
import PrimaryContentContainer from '../../components/PrimaryContentContainer'
import URLParamHelper from '../../helpers/URLParamHelper'
import Redirect from '../../helpers/redirectHelper'
import SomethingWentWrong from '../../components/SomethingWentWrong'

function AuthPage() {
  const [hasSentRequest, setHasSentRequest] = useState(false)

  const [state, setState] = useState(
    !URLParamHelper.get('code') || URLParamHelper.get('error')
      ? 'error'
      : 'loading'
  )

  if (URLParamHelper.get('code') && !hasSentRequest) {
    const init = {
      body: {
        code: URLParamHelper.get('code'),
        redirect_uri: window.location.origin + '/auth/',
      },
    }
    API.post('userGateway', '/auth/authorize', init)
      .then(res => {
        if (res.error) return setState('error')
        setState('authenticating')
        Auth.signIn(res.email, res.password)
          .then(() => setState('success'))
          .catch(e => {
            setState('error')
            console.error('Error: ' + JSON.stringify(e))
          })
      })
      .catch(err => {
        setState('error')
      })
    setHasSentRequest(true)
  }

  if (state === 'loading') {
    return (
      <PrimaryContentContainer
        key={0}
        title='Loading data...'
        hideBottom={true}
      />
    )
  }

  if (state === 'authenticating') {
    return (
      <PrimaryContentContainer
        key={1}
        title='Authenticating...'
        hideBottom={true}
      />
    )
  }

  return (
    <Redirect to='/portal/accounts' ifAuthStateIs={true} passParams={true}>
      <SomethingWentWrong goBackLink='/portal/login' />
    </Redirect>
  )
}

export default AuthPage
