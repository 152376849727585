import { AuthState } from '@aws-amplify/ui-components'

const initialState = {
  connections: [],
  truelayerCode: null,
  reauthNonce: null,
  authState: AuthState.SignedOut,
  hasConsented: false,
  hasLoadedUser: false, // whether user has been loaded from backend or not
  devReal: false, //if adding real bank accounts in dev
  user: {},
  //  referrerParam: ''
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'logout':
      return { ...initialState, hasLoadedUser: true }
    case 'changeState':
      return { ...state, ...action.payload }
    case 'changeUser':
      return {
        ...state,
        user: action.payload,
        authState: AuthState.SignedIn,
        hasLoadedUser: true,
      }
    //    case 'changeReferrerParam':
    //      return { ...state, referrerParam: action.payload}
    case 'changeConnections':
      return {
        ...state,
        connections: action.payload,
        hasLoadedUser: true,
      }
    case 'changeAuthState':
      return { ...state, authState: action.payload }
    case 'changeHasConsented':
      return { ...state, hasConsented: action.payload }
    case 'saveTruelayerCode':
      return { ...state, truelayerCode: action.payload }
    case 'saveReauthNonce':
      return { ...state, reauthNonce: action.payload }
    case 'changeDevReal':
      return { ...state, devReal: action.payload }
    default:
      return { ...state, ...action.payload }
  }
}

export default rootReducer
