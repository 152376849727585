/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      yougov_id
      consented
      accounts {
        items {
          id
          user_id
          type
          account_type
          card_network
          card_type
          currency
          display_name
          iban
          name_on_card
          number
          partial_card_number
          provider_display_name
          provider_id
          provider_logo_uri
          routing_number
          sort_code
          swift_bic
          valid_from
          valid_to
          refresh_token
          consent_expired
          consent_revoked
          consent_last_renewed
          reward_last_received
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        yougov_id
        accounts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      user_id
      type
      account_type
      card_network
      card_type
      currency
      display_name
      iban
      name_on_card
      number
      partial_card_number
      provider_display_name
      provider_id
      provider_logo_uri
      routing_number
      sort_code
      swift_bic
      valid_from
      valid_to
      refresh_token
      consent_expired
      consent_revoked
      consent_last_renewed
      reward_last_received
      createdAt
      updatedAt
    }
  }
`
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        type
        account_type
        card_network
        card_type
        currency
        display_name
        iban
        name_on_card
        number
        partial_card_number
        provider_display_name
        provider_id
        provider_logo_uri
        routing_number
        sort_code
        swift_bic
        valid_from
        valid_to
        refresh_token
        consent_expired
        consent_revoked
        consent_last_renewed
        reward_last_received
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      account_id
      user_id
      timestamp
      description
      amount
      currency
      transaction_type
      transaction_category
      transaction_classification
      merchant_name
      createdAt
      updatedAt
    }
  }
`
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account_id
        user_id
        timestamp
        description
        amount
        currency
        transaction_type
        transaction_category
        transaction_classification
        merchant_name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
