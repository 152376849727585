import React from 'react'
import AuthUI from '../../components/AuthUI'
import { AuthState } from '@aws-amplify/ui-components'
import { useSelector } from 'react-redux'
import URLParamHelper from '../../helpers/URLParamHelper'
import { Container } from 'react-bootstrap'
import Redirect from '../../helpers/redirectHelper'
import Loader from '../../components/Loader'

function SignUpPage(props) {
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const authState = useSelector(({ authState }) => authState)
  const hasLoadedUser = useSelector(({ hasLoadedUser }) => hasLoadedUser)

  function onChange(e) {
    props.loginManager.onChange(e)
  }

  function signUp() {
    setErrorMessage('')
    setLoading(true)
    props.loginManager
      .signUp()
      .then(() => {
        setLoading(false)
      })
      .catch(e => {
        setErrorMessage(e.message)
        setLoading(false)
      })
  }

  function confirmSignUp() {
    setErrorMessage('')
    setLoading(true)
    props.loginManager.confirmSignUp().catch(e => {
      setErrorMessage(e.message)
      setLoading(false)
    })
  }

  const getLink = page => {
    return '/' + page
  }

  const SignUpStep = () => {
    if (!hasLoadedUser) return <Loader />

    if (authState === AuthState.ConfirmSignUp) {
      return (
        <AuthUI
          key={1}
          onChange={onChange}
          onSubmit={confirmSignUp}
          errorMessage={errorMessage}
          title='Confirm email'
          submitText='Confirm'
          noEmail={true}
          noPassword={true}
          loading={loading}
          otherLink={<span></span>}></AuthUI>
      )
    }

    return (
      <AuthUI
        onChange={onChange}
        onSubmit={signUp}
        title='Create an account'
        submitText='Sign up'
        errorMessage={errorMessage}
        noValidation={true}
        loading={loading}
        otherLink={
          <span>
            Already have an account?{' '}
            <a href={getLink('portal/login')}>Sign in</a>
          </span>
        }></AuthUI>
    )
  }

  const FromMessage = () => {
    const MessageContainer = ({ message }) => (
      <Container
        style={{
          padding: 40,
          textAlign: 'left',
          maxWidth: 'var(--primary-max-width)',
          color: 'var(--main-color)',
        }}>
        {message}
      </Container>
    )
    const from = URLParamHelper.get('from')
    if (from === 'add-bank-account')
      return (
        <MessageContainer message='Please sign up before you add your bank account.' />
      )
    return <></>
  }

  return (
    <Redirect ifAuthStateIs={true} passParams={true}>
      <FromMessage />
      <SignUpStep />
    </Redirect>
  )
}

export default SignUpPage
