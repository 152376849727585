import PrimaryContentContainer from '../../components/PrimaryContentContainer'
import SomethingWentWrong from '../../components/SomethingWentWrong'
import URLParamHelper from '../../helpers/URLParamHelper'

import '../AddBankAccountPage/AddBankAccountPage.css'

function TruelayerRedirectPage() {
  if (URLParamHelper.get('code')) {
    return (
      <PrimaryContentContainer
        title='Congratulations!'
        hideButton={true}
        progress={100}
        points={500}>
        <b>
          Thank you for refreshing the access to your bank account. If
          everything went well, you will recieve 500 points in the next few
          business days.
        </b>
      </PrimaryContentContainer>
    )
  }
  return (
    <SomethingWentWrong>
      If you would like to try again, please follow the link in the email we
      sent you.
    </SomethingWentWrong>
  )
}

export default TruelayerRedirectPage
