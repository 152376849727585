import store from '../store/store'
import { Auth } from 'aws-amplify'
import { Hub, API } from 'aws-amplify'
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import { AuthState } from '@aws-amplify/ui-components'
import URLParamHelper from './URLParamHelper'

class LoginManager {
  constructor() {
    this.formState = 'signIn'
    this.authFormState = {
      email: '',
      password: '',
      verificationCode: '',
    }

    this.updateAuthFormState = function (name, value) {
      this.authFormState[name] = value
    }

    /* onChange handler for form inputs */
    this.onChange = function (e) {
      this.updateAuthFormState(e.target.name, e.target.value)
    }

    /* Sign up function */
    this.signUp = async function () {
      try {
        if (document.querySelector("input[name='email']")?.value?.length)
          this.updateAuthFormState(
            'email',
            document.querySelector("input[name='email']").value
          )
        if (document.querySelector("input[name='password']")?.value?.length)
          this.updateAuthFormState(
            'password',
            document.querySelector("input[name='password']").value
          )
        await Auth.signUp({
          username: this.authFormState.email,
          password: this.authFormState.password,
          attributes: {
            email: this.authFormState.email,
            'custom:ssoUser': '0',
          },
        })
        this.formState = 'confirmSignUp'
      } catch (err) {
        throw err
      }
    }

    /* Confirm sign up function for MFA */
    this.confirmSignUp = async function () {
      try {
        if (
          document.querySelector("input[name='verificationCode']")?.value
            ?.length
        )
          this.updateAuthFormState(
            'verificationCode',
            document.querySelector("input[name='verificationCode']").value
          )
        await Auth.confirmSignUp(
          this.authFormState.email,
          this.authFormState.verificationCode
        )
        await this.signIn()
      } catch (err) {
        throw err
      }
    }

    /* Sign in function */
    this.signIn = async function () {
      try {
        if (document.querySelector("input[name='email']")?.value?.length)
          this.updateAuthFormState(
            'email',
            document.querySelector("input[name='email']").value
          )
        if (document.querySelector("input[name='password']")?.value?.length)
          this.updateAuthFormState(
            'password',
            document.querySelector("input[name='password']").value
          )
        await Auth.signIn(this.authFormState.email, this.authFormState.password)
        this.formState = 'signedIn'
      } catch (err) {
        throw err
      }
    }

    this.signOut = async function () {
      try {
        await Auth.signOut()
      } catch (error) {
        console.error('error signing out: ', error)
      }
    }

    /* Forgot password function */
    this.forgotPassword = async function () {
      try {
        if (document.querySelector("input[name='email']")?.value?.length)
          this.updateAuthFormState(
            'email',
            document.querySelector("input[name='email']").value
          )
        await Auth.forgotPassword(this.authFormState.email)
        this.formState = 'resettingpassword'
      } catch (err) {
        throw err
      }
    }

    /* Reset password function */
    this.forgotPasswordSubmit = async function () {
      try {
        if (
          document.querySelector("input[name='verificationCode']")?.value
            ?.length
        )
          this.updateAuthFormState(
            'verificationCode',
            document.querySelector("input[name='verificationCode']").value
          )
        if (document.querySelector("input[name='password']")?.value?.length)
          this.updateAuthFormState(
            'password',
            document.querySelector("input[name='password']").value
          )
        await Auth.forgotPasswordSubmit(
          this.authFormState.email,
          this.authFormState.verificationCode,
          this.authFormState.password
        )
        window.location.href =
          '/portal/login/?from=reset-password&email=' + this.authFormState.email
      } catch (err) {
        throw err
      }
    }

    const listener = data => {
      switch (data.payload.event) {
        case 'signIn':
          this.loadData()
          store.dispatch({
            type: 'changeAuthState',
            payload: AuthState.SignedIn,
          })
          break
        case 'signUp':
          store.dispatch({
            type: 'changeAuthState',
            payload: AuthState.ConfirmSignUp,
          })
          break
        case 'signIn_failure':
          break
        case 'forgotPassword':
          store.dispatch({
            type: 'changeAuthState',
            payload: AuthState.ResetPassword,
          })
          break
        case 'tokenRefresh':
          break
        case 'tokenRefresh_failure':
          break
        case 'configured':
          this.loadData(Math.random())
          break
        case 'signOut':
          this.loadData()
          break
        default:
          break
      }
    }
    Hub.listen('auth', listener)
  }

  remainingDaysOfAccess(account) {
    if (
      account.reward_last_received &&
      Math.ceil(
        (new Date().getTime() -
          new Date(account.consent_last_renewed).getTime()) /
          (1000 * 3600 * 24)
      ) > 2
    ) {
      let expiresAt = new Date(account.reward_last_received)
      expiresAt.setMonth(expiresAt.getMonth() + 3)
      expiresAt.setDate(expiresAt.getDate() - 1)
      return Math.ceil(
        (new Date(expiresAt).getTime() - new Date().getTime()) /
          (1000 * 3600 * 24)
      )
    } else {
      let expiresAt = new Date(account.consent_last_renewed)
      expiresAt.setMonth(expiresAt.getMonth() + 3)
      return Math.ceil(
        (new Date(expiresAt).getTime() - new Date().getTime()) /
          (1000 * 3600 * 24)
      )
    }
  }

  loadData() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        return Auth.currentUserInfo()
      })
      .then(data => {
        if (data !== null) {
          this.formState = 'signedIn'
          let utm_param = URLParamHelper.get('utm_campaign')
          if (utm_param && utm_param.indexOf('ygrc') > -1) {
            API.graphql({
              query: mutations.updateUser,
              variables: {
                input: {
                  id: data.username,
                  came_from: 'recontact' + utm_param[4],
                },
              },
            })
          }
          API.graphql({
            query: queries.getUser,
            variables: {
              id: data.username,
            },
          }).then(userData => {
            if (userData.data.getUser?.hasOwnProperty('accounts')) {
              let accs = {}
              userData.data.getUser.accounts.items.forEach(ac => {
                if (!accs.hasOwnProperty(ac.refresh_token)) {
                  accs[ac.refresh_token] = {}
                  accs[ac.refresh_token]['token'] = ac.refresh_token
                  accs[ac.refresh_token]['remaining'] =
                    this.remainingDaysOfAccess(ac)
                  accs[ac.refresh_token]['expired'] = ac.consent_expired
                  accs[ac.refresh_token]['revoked'] = ac.consent_revoked
                  accs[ac.refresh_token]['accounts'] = [ac]
                } else {
                  accs[ac.refresh_token]['accounts'].push(ac)
                }
              })
              let ref_tokens = [
                ...new Set(
                  userData.data.getUser.accounts.items.map(
                    ac => ac.refresh_token
                  )
                ),
              ]
              ref_tokens.forEach(t => {
                let accounts = accs[t]['accounts']
                if (
                  accounts.filter(
                    ac => !ac.consent_expired && !ac.consent_revoked
                  ).length > 0
                ) {
                  accs[t]['expired'] = false
                  accs[t]['revoked'] = false
                  accs[t]['remaining'] = this.remainingDaysOfAccess(
                    accounts.filter(
                      ac => !ac.consent_expired && !ac.consent_revoked
                    )[0]
                  )
                }
              })
              store.dispatch({
                type: 'changeConnections',
                payload: Object.keys(accs).map(t => accs[t]),
              })
            }
            if (userData.data.getUser?.consented)
              store.dispatch({
                type: 'changeHasConsented',
                payload: true,
              })
            store.dispatch({
              type: 'changeUser',
              payload: data,
            })
          })
        } else {
          store.dispatch({ type: 'logout' })
          this.formState = 'signIn'
        }
      })
      .catch(e => {
        store.dispatch({ type: 'logout' })
        this.formState = 'signIn'
      })
  }

  deleteAccount(account) {
    const init = {
      body: {
        account_id: account.id,
        user_id: account.user_id,
      },
    }
    return API.del('userGateway', '/data/deleteaccount', init)
      .then(res => {
        if (res.error) console.error(res.error)
        else this.loadData()
      })
      .catch(err => console.error('Error, ', err))
  }

  extendConnections(
    refresh_tokens,
    user_id,
    email,
    redirect_uri,
    dev_use_real = false
  ) {
    let init = {
      body: {
        user_id: user_id,
        refresh_tokens: refresh_tokens,
        email: email,
        redirect_uri: redirect_uri,
      },
    }
    if (dev_use_real) {
      init['body']['dev_real'] = dev_use_real
    }
    return API.post('userGateway', '/data/extendconnections', init)
      .then(res => {
        return res
      })
      .catch(err => {
        return { error: err }
      })
  }

  revokeConnections(tokens, user_id, redirect_uri, dev_use_real = false) {
    let init = {
      body: {
        user_id: user_id,
        refresh_tokens: tokens,
        redirect_uri,
      },
    }
    if (dev_use_real) {
      init['body']['dev_real'] = dev_use_real
    }
    return API.del('userGateway', '/data/revokeconnections', init)
      .then(res => {
        if (!res.error) this.loadData()
        return res
      })
      .catch(err => console.error('Error, ', err))
  }

  storeReauthenticationState(
    reauthenticating_token,
    nonce,
    user_id,
    tokens,
    states,
    reauth_links,
    nonces,
    dev_real = false
  ) {
    let init = {
      body: {
        user_id: user_id,
        nonce: nonce,
        reauthenticating_token: reauthenticating_token,
        refresh_tokens: tokens,
        states: states,
        reauth_links: reauth_links,
        nonces: nonces,
      },
    }
    if (dev_real) {
      init['body']['dev_real'] = true
    }
    return API.post('userGateway', '/data/reauthenticationstate', init)
      .then(res => {
        return res
      })
      .catch(err => console.error('Error, ', err))
  }

  getReauthenticationState(nonce, user_id) {
    const init = {
      body: {
        user_id: user_id,
        nonce: nonce,
      },
    }
    return API.post('userGateway', '/data/reauthenticationstate', init)
      .then(res => {
        return res
      })
      .catch(err => console.error('Error, ', err))
  }
}

const loginManager = new LoginManager()

export default loginManager
