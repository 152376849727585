/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_identity_pool_id:
    'eu-west-2:393074e3-89f6-4679-99b7-0c5d89819ee6',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_TnLSgi4bH',
  aws_user_pools_web_client_id: '5iulfkaulsh3vtv0lga941vo3v',
  federationTarget: 'COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint:
    'https://nlmhfe3l4vay7l2sxo7tqw7qke.appsync-api.eu-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AWS_IAM',
  aws_cloud_logic_custom: [
    {
      name: 'userGateway',
      endpoint: 'https://gk6xxh5fjf.execute-api.eu-west-2.amazonaws.com/prod',
      region: 'eu-west-2',
    },
  ],
  aws_user_files_s3_bucket: 'user-file-storage135821-prod',
  aws_user_files_s3_bucket_region: 'eu-west-2',
  aws_dynamodb_all_tables_region: 'eu-west-2',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'ssoHelperStorage-prod',
      region: 'eu-west-2',
    },
    {
      tableName: 'emailUserIDs-prod',
      region: 'eu-west-2',
    },
    {
      tableName: 'clientInterestForm-prod',
      region: 'eu-west-2',
    },
  ],
}

export default awsmobile
