import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { API } from 'aws-amplify'

import PrimaryContentContainer from '../../components/PrimaryContentContainer'
import Redirect from '../../helpers/redirectHelper'
import Logo from '../../components/Logo'
import URLParamHelper from '../../helpers/URLParamHelper'

import './SSOPage.css'

const CLIENT_ID = 'm7V8MtERSbS67R9pHkhqdRbS'
const STATE = '1234'
const NONCE = Date.now().toString()
const URL = encodeURIComponent(window.location.origin) + '%2Fauth%2F'

const SSO_URL =
  'https://account.yougov.com/api/auth/v1/oauth/authorize?response_type=code&' +
  'client_id=' +
  CLIENT_ID +
  '&redirect_uri=' +
  URL +
  '&state=' +
  STATE +
  '&nonce=' +
  NONCE

const SignInPage = () => {
  const hasLoadedUser = useSelector(({ hasLoadedUser }) => hasLoadedUser)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState('normal')
  const [shownQuestion, setShownQuestion] = useState(-1)
  const referrerURL = document.referrer
  const referrerParam = URLParamHelper.get('utm_campaign')

  if (!hasLoadedUser)
    return <Spinner animation='border' variant='light' size='lg' />

  const redirectToSSO = e => {
    setLoading(true)
    setState('normal')
    const data = {
      body: {
        nonce: NONCE,
        referrer_url: referrerURL,
        referrer_param: referrerParam,
      },
    }
    API.post('userGateway', '/auth/savenonce', data)
      .then(res => {
        if (res.error) {
          setLoading(false)
          return setState('error')
        }
        window.location.href = SSO_URL
      })
      .catch(err => {
        setLoading(false)
        setState('error')
      })
  }

  return (
    <Redirect ifAuthStateIs={true} passParams={true}>
      <PrimaryContentContainer
        noMinHeight={true}
        hideBottom={true}
        className='SSO-page'>
        <div className='container'>
          <div className='primary'>
            Welcome to<br></br>
            <Logo height='40px' color='white' />
          </div>
          <div
            className='error'
            style={{
              display: state === 'error' ? 'block' : 'none',
            }}>
            Something went wrong. Please try again later.
          </div>
          <div className='button-container'>
            <button
              className={'zoom-hover ' + (loading ? 'loading' : '')}
              onClick={() => redirectToSSO()}>
              <img src='/favicon.ico' alt='YouGov Logo' />
              <Spinner
                animation='border'
                variant='dark'
                size='sm'
                style={{
                  display: loading ? 'inline-block' : 'none',
                }}
              />
              {loading ? '' : 'Sign in with YouGov'}
            </button>
          </div>
          <div
            style={{
              textAlign: 'center',
              width: 300,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            <div
              className='little-question-div'
              style={{
                marginTop: 40,
                fontWeight: shownQuestion === 0 ? 'bold' : 'unset',
              }}
              onClick={() => {
                setShownQuestion(shownQuestion === 0 ? -1 : 0)
              }}>
              How will my data be used?
            </div>
            <div
              className='little-answer-div'
              style={{
                display: shownQuestion === 0 ? 'block' : 'none',
              }}>
              Your data will be used to produce aggregated and anonymous
              research as part of our transaction data products for our clients.
            </div>
            <div
              className='little-question-div'
              style={{
                fontWeight: shownQuestion === 1 ? 'bold' : 'initial',
              }}
              onClick={() => {
                setShownQuestion(shownQuestion === 1 ? -1 : 1)
              }}>
              How does YouGov Finance work?
            </div>
            <div
              className='little-answer-div'
              style={{
                display: shownQuestion === 1 ? 'block' : 'none',
              }}>
              We use Open Banking to collect transaction data which we process
              to uncover market insights and analyse consumer behaviour.
            </div>
            <div
              className='little-question-div'
              style={{
                fontWeight: shownQuestion === 2 ? 'bold' : 'initial',
              }}
              onClick={() => {
                setShownQuestion(shownQuestion === 2 ? -1 : 2)
              }}>
              What will I earn?
            </div>
            <div
              className='little-answer-div'
              style={{
                display: shownQuestion === 2 ? 'block' : 'none',
              }}>
              You will be rewarded 500 YouGov points for active GBP current
              accounts and credit cards, and 100 points for savings accounts,
              within 48 hours.
            </div>
            <a
              className='little-question-div'
              href='https://yougov.zendesk.com/hc/en-gb/sections/4413107645841-YouGov-Finance'
              rel='noreferrer'
              style={{
                color: 'white',
                display: 'block',
                marginTop: 10,
              }}
              target='_blank'>
              Learn more
            </a>
          </div>
        </div>
      </PrimaryContentContainer>
    </Redirect>
  )
}

export default SignInPage
