import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import PortalPages from './PortalPages'
import loginManager from './helpers/loginManager'
import BackgroundImage from './assets/backgrounds/background.png'
import PortalNavBar from './components/PortalNavBar'

const PageContent = props => {
  const MainComponent = props.component

  return (
    <>
      <PortalNavBar hideRightItems={props.hideRightItems ?? false} />
      <MainComponent {...props} />
    </>
  )
}

function App() {
  document.title = 'YouGov'

  return (
    <Router>
      <Switch>
        <Route exact path='/datahub'>
          <PortalPages.DataHubPage />
        </Route>
        <div
          className='App'
          style={{ backgroundImage: `url(${BackgroundImage})` }}>
          <div className='main-container justify-content-center'>
            <Switch>
              <Route exact path='/'>
                <PageContent component={PortalPages.LandingPage} />
              </Route>
              <Route exact path='/portal'>
                <Redirect to='/' />
              </Route>
              <Route exact path='/auth'>
                <PageContent
                  component={PortalPages.AuthPage}
                  hideRightItems={true}
                />
              </Route>
              <Route exact path='/portal/login'>
                <PageContent
                  component={PortalPages.SSOPage}
                  hideRightItems={true}
                />
              </Route>
              <Route exact path='/portal/old-login'>
                <PageContent
                  component={PortalPages.SignInPage}
                  loginManager={loginManager}
                  hideRightItems={true}
                />
              </Route>
              <Route exact path='/portal/sign-up'>
                <PageContent
                  component={PortalPages.SignUpPage}
                  loginManager={loginManager}
                  hideRightItems={true}
                />
              </Route>
              <Route exact path='/portal/reset-password'>
                <PageContent
                  component={PortalPages.ResetPasswordPage}
                  loginManager={loginManager}
                  hideRightItems={true}
                />
              </Route>
              <Route exact path='/portal/accounts'>
                <PageContent component={PortalPages.AccountsPage} />
              </Route>
              <Route path='/portal/add-bank-account'>
                <Redirect to='/portal/accounts' />
              </Route>
              <Route path='/reconfirm-consent'>
                <Redirect to='/portal/accounts' />
              </Route>
              <Route path='/save-bank-account'>
                <Redirect to='/portal/accounts' />
              </Route>
              <Route path='/save-bank-refresh'>
                <PageContent component={PortalPages.TruelayerRedirectPage} />
              </Route>
              <Route path='/terms'>
                <PageContent component={PortalPages.PolicyPage} file='terms' />
              </Route>
              <Route path='/privacy-policy'>
                <PageContent
                  component={PortalPages.PolicyPage}
                  file='privacy-notice'
                />
              </Route>
              <Route path='/404'>
                <PageContent component={PortalPages.Page404} />
              </Route>
              <Redirect to='/404' />
            </Switch>
          </div>
          <footer>
            <Container>
              <Row>
                <Col>United Kingdom</Col>
                <Col>Tel - +44 (0)333 3050899, 8am-6pm</Col>
              </Row>
              <Row>
                <Col>
                  <a href='/terms'>Terms & Conditions</a>
                </Col>
                <Col>
                  Email -{' '}
                  <a href='mailto:info@yougov.finance'>info@yougov.finance</a>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      </Switch>
    </Router>
  )
}

export default App
