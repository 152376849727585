import './LandingPage.css'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { AuthState } from '@aws-amplify/ui-components'
import { useSelector } from 'react-redux'
import URLParamHelper from '../../helpers/URLParamHelper'
import Redirect from '../../helpers/redirectHelper'

function LandingPage() {
  const [shownQuestion, setShownQuestion] = React.useState(-1)
  const hasLoadedUser = useSelector(({ hasLoadedUser }) => hasLoadedUser)
  const authState = useSelector(({ authState }) => authState)
  const connections = useSelector(({ connections }) => connections)

  let startURL = '/portal/accounts'
  if (
    hasLoadedUser &&
    authState === AuthState.SignedIn //&&
    //connections.length > 0
  )
    return <Redirect to='/portal/accounts' ifAuthStateIs={true} />

  return (
    <Container className='landing-page'>
      <Row>
        <Col md='6' sm='12'>
          <h2 className=''>Unlock the power of your financial data.</h2>
          <p>Connect your bank account and earn rewards from your data.</p>
          <a href={startURL} className='main-button zoom-hover'>
            Start
          </a>
          <div
            className='little-question-div'
            style={{
              marginTop: 40,
              fontWeight: shownQuestion === 0 ? 'bold' : 'unset',
            }}
            onClick={() => {
              setShownQuestion(shownQuestion === 0 ? -1 : 0)
            }}>
            How will my data be used?
          </div>
          <div
            className='little-answer-div'
            style={{
              display: shownQuestion === 0 ? 'block' : 'none',
            }}>
            Your data will be used to produce aggregated and anonymous research
            as part of our transaction data products for our clients.
          </div>
          <div
            className='little-question-div'
            style={{
              fontWeight: shownQuestion === 1 ? 'bold' : 'initial',
            }}
            onClick={() => {
              setShownQuestion(shownQuestion === 1 ? -1 : 1)
            }}>
            How does YouGov Finance work?
          </div>
          <div
            className='little-answer-div'
            style={{
              display: shownQuestion === 1 ? 'block' : 'none',
            }}>
            We use Open Banking to collect transaction data which we process to
            uncover market insights and analyse consumer behaviour.
          </div>
          <div
            className='little-question-div'
            style={{
              fontWeight: shownQuestion === 2 ? 'bold' : 'initial',
            }}
            onClick={() => {
              setShownQuestion(shownQuestion === 2 ? -1 : 2)
            }}>
            What will I earn?
          </div>
          <div
            className='little-answer-div'
            style={{
              display: shownQuestion === 2 ? 'block' : 'none',
            }}>
            You will be rewarded 500 YouGov points for active GBP current
            accounts and credit cards, and 100 points for savings accounts,
            within 48 hours.
          </div>
          <a
            className='little-question-div'
            href='https://yougov.zendesk.com/hc/en-gb/sections/4413107645841-YouGov-Finance'
            rel='noreferrer'
            style={{
              color: 'white',
              display: 'block',
              marginTop: 10,
            }}
            target='_blank'>
            Learn more
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default LandingPage
