import { Spinner } from 'react-bootstrap'

const Loader = props => (
  <Spinner
    animation='border'
    size='lg'
    {...props}
    style={{ color: props.color || 'var(--main-color)', ...props.style }}
  />
)

export default Loader
