import './PrimaryContentContainer.css'

import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { CaretLeftFill } from 'react-bootstrap-icons'
import Loader from './Loader'

function PrimaryContentContainer(props) {
  const [checked, setChecked] = useState(false)

  return (
    <Container fluid className={props.className + ' primary-content-container'}>
      <Container className='main-container'>
        <Col>
          <Row>
            <h3 style={{ display: props.title ? 'block' : 'none' }}>
              {props.title}
            </h3>
            <div
              className='children'
              style={{ minHeight: props.noMinHeight ? 0 : 200 }}>
              {props.children}
            </div>
          </Row>
          <Row
            className='checkbox-and-steps'
            style={{
              display:
                props.checkboxText || (props.step && props.totalSteps)
                  ? 'flex'
                  : 'none',
            }}>
            <span className='checkbox-text'>
              <label htmlFor='main-checkbox'>{props.checkboxText}</label>
              <input
                id='main-checkbox'
                type='checkbox'
                onChange={val => setChecked(val.currentTarget.checked)}
                style={{
                  display: props.useCheckbox ? 'inline' : 'none',
                }}
              />
            </span>
            <span
              style={{
                display:
                  props.step == null || props.totalSteps == null
                    ? 'none'
                    : 'inline',
              }}>
              (Step {props.step} / {props.totalSteps})
            </span>
          </Row>
        </Col>
      </Container>
      <Container
        fluid
        className='progress-container'
        style={{
          display: !props.progress && props.progress !== 0 ? 'none' : 'block',
        }}>
        <div className='progress-background'></div>
        <div className='progress' style={{ width: props.progress + '%' }}></div>
      </Container>
      <Container
        className='points-and-buttons'
        style={{ display: props.hideBottom ? 'none' : 'block' }}>
        <Row>
          <Col sm={12} md={6} className='text-md-left points text-center'></Col>
          <Col
            sm={12}
            md={6}
            className='justify-content-between justify-content-md-end'
            style={{ display: 'flex' }}>
            <span
              className='backLink'
              onClick={props.backLink}
              style={{
                display: props.backLink ? 'inline' : 'none',
              }}>
              <CaretLeftFill /> Back
            </span>
            <Loader
              id='primary-content-container-spinner'
              size='sm'
              style={{
                display: props.showLoading ? 'block' : 'none',
              }}
            />
            {props.buttons ? (
              props.buttons.map((b, i) => (
                <button
                  id='next-button'
                  key={i}
                  disabled={b.nextLinkDisabled}
                  onClick={b.nextLink}
                  style={{
                    display: b.hideButton ? 'none' : 'inline',
                    marginLeft: 10,
                  }}>
                  {b.text}
                </button>
              ))
            ) : (
              <button
                id='next-button'
                disabled={
                  (props.useCheckbox && !checked) || props.nextLinkDisabled
                }
                onClick={props.nextLink}
                style={{
                  display: props.hideButton ? 'none' : 'inline',
                }}>
                Next
              </button>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default PrimaryContentContainer
