import React from 'react'

import PrimaryContentContainer from '../../components/PrimaryContentContainer'

function Page404() {
  return (
    <PrimaryContentContainer
      hideBottom={true}
      noMinHeight={true}
      title='Error 404'>
      The page you are looking for does not exist.{' '}
      <a href='/'>Take me back home</a>
    </PrimaryContentContainer>
  )
}

export default Page404
