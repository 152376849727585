//import './Terms.css'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Policy(props) {
  window.onload = function (e) {
    var iframe = document.querySelector('#iframe')
    iframe.height = iframe.contentWindow.document.body.scrollHeight + 20
  }

  return (
    <>
      <Container
        style={{
          backgroundColor: 'rgba(255,255,255,0.9)',
          maxWidth: 'var(--primary-max-width)',
          padding: 0,
          textAlign: 'left',
          fontSize: 14,
          letterSpacing: -0.8,
          marginBottom: '30px',
        }}>
        <Col>
          <Row>
            <iframe
              id='iframe'
              title='policy-iframe'
              src={props.src}
              style={{ width: '100%' }}
            />
          </Row>
        </Col>
      </Container>
    </>
  )
}

export default Policy
